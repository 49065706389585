import { createSlice } from '@reduxjs/toolkit';

interface initState {
  videoInfo: {
    docsName: string | undefined;
    videoUrl: string | undefined;
    duration: string;
  };
  recognitionType: string;
  draftVideoInfo: {
    id: number;
    videoName: string;
    videoUrl: string;
    videoDuration: string;
  };
}

const initialState: initState = {
  videoInfo: {
    docsName: undefined,
    videoUrl: undefined,
    duration: '',
  },
  recognitionType: '', // ai换脸的操作类型 handle or ai
  draftVideoInfo: {
    id: 0,
    videoName: '',
    videoUrl: '',
    videoDuration: '',
  },
};

export const faceSwapSlice = createSlice({
  name: 'faceSwap',
  initialState,
  reducers: {
    setVideoInfo: (state, action) => {
      state.videoInfo = action.payload;
    },
    setRecognitionType: (state, action) => {
      state.recognitionType = action.payload;
    },
    setDraft: (stage, action) => {
      stage.draftVideoInfo = action.payload;
    },
  },
});
// 每个 case reducer 函数会生成对应的 Action creators
export const { setVideoInfo, setRecognitionType, setDraft } = faceSwapSlice.actions;

export default faceSwapSlice.reducer;
