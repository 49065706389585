import { useState, useEffect, forwardRef, useImperativeHandle, Ref } from 'react';
import { CloseOutlined, SyncOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Button, Modal, Form, Input, message as $message, Space } from 'antd';
import QRCode from 'qrcode.react';
import CryptoJS from 'crypto-js';

import './loginRegisterModal.less';
import { isMobile, isOriginSkyink } from '@/utils/index';
import ls from '@/utils/storage';
import { CheckSquareOutlined } from '@ant-design/icons';
import {
  WHALE_WISDOM_CLOUDS_TOKEN,
  URL_EXPEND,
  INVITE_TOKEN,
  BIND_TOKEN,
  COMPANY_ID,
} from '@/utils/config';
import { getUserInfo } from '@/config/api/login';
import { store } from '@/store';
import { setUserInfo, setAccountInfo, setMessageInfo, setCompanyInfo } from '@/store/features/init';

import codePng from '@/assets/whale/code.png';
import iphonePng from '@/assets/whale/iphone.png';

//import {
//  getUserInfo
//} from '@/service';
import {
  login,
  getVerificationCodeService,
  wxCode,
  wxScan,
  bindPhone,
  userAgreement,
  mb_expand,
} from '@/config/api/login';

const { confirm } = Modal;

let verificationCodeCountTimer: any = null;

let qrCodeTimer: any = null;

const originSkyink = isOriginSkyink();

const LoginRegisterModal = forwardRef((_props, ref: Ref<any>) => {
  const [isShow, changeModalShowStatus] = useState(false); // modal
  //const [typeStr, setTypeStr] = useState('mobile'); // 登录方式 手机or验证码
  const [isCountdown, changeCountdown] = useState(false); // 是否验证码
  const [verificationCodeCount, changeVerificationCodeCount] = useState(60); // 验证码倒计时
  const [form] = Form.useForm(); // 注册
  const [loginForm] = Form.useForm(); // 登录
  const [forgotForm] = Form.useForm(); // 忘记密码
  const [isRegisterSuccess, changeRegistertatus] = useState(false); // 是否是注册
  const [isCheck, setIsCheck] = useState<boolean>(false); // 是否选中已阅协议
  const [loginMode, setLoginMode] = useState<string>('qrCode'); // 登录方式 qrCode or verificationCode

  const [qrcodeUrl, setQrcodeUrl] = useState<string>(''); // 获取二维码时间
  const [qrcodeUrlTime, setQrcodeUrlTime] = useState<number>(0); // 二维码的有效时间
  const [isRefresh, setIsRefresh] = useState(false); // 是否二维码过期
  const [isWXBind, setIsWXBind] = useState<boolean>(false); // 是否是微信扫码绑定手机号的情况
  const [buttonLoading, setButtonLoading] = useState<boolean>(false); // 用于点击按钮 增加loading
  const [bindToken, setBindToken] = useState<string | undefined>(undefined); // 微信扫码未绑定手机号下记录token来调用接口

  function jumpServe(type?: string) {
    if (type === 'usage') {
      if (originSkyink) {
        window.open('userAgreement_ydmb.html');
      } else {
        window.open('userAgreement.html');
      }
    } else {
      if (originSkyink) {
        window.open('privacyAgreement_ydmb.html');
      } else {
        window.open('privacyAgreement.html');
      }
    }
  }

  // 同意协议的基础项
  const confirmOptions = {
    icon: <InfoCircleFilled />,
    title: '服务协议及隐私政策',
    maskClosable: false,
    className: 'agreementModal',
    style: { top: 250 },
    content: (
      <div>
        <p>
          登录即代表你同意鲸智云的
          <b style={{ cursor: 'pointer' }} onClick={() => jumpServe('usage')}>
            使用协议
          </b>
          和
          <b style={{ cursor: 'pointer' }} onClick={() => jumpServe('privacyPolicy')}>
            隐私政策
          </b>
        </p>
      </div>
    ),
    okText: '同意',
    cancelText: '不同意',
  };

  /**
   * 初始化modal
   * @param type 是否是云端妙笔的域名
   */
  function init(type: boolean) {
    if (type) {
      setLoginMode('verificationCode');
      changeModalShowStatus(true);
    } else {
      setLoginMode('qrCode');
      getWxCode('init');
    }
  }
  //// 注册 立即入驻
  //function handleOk() {
  //  changeModalShowStatus(false);
  //  navigate('/settleIn');
  //}

  /**
   * 取消关闭modal
   */
  function handleCancel(typeStr?: string) {
    if (isWXBind && typeStr !== 'bind') {
      $message.info('未登录成功，请重新扫码绑定手机号登录');
      ls.set(WHALE_WISDOM_CLOUDS_TOKEN, undefined);
    }
    setBindToken(undefined);
    ls.set(BIND_TOKEN, undefined);
    changeModalShowStatus(false);
    setIsCheck(false);
    changeCountdown(false);
    changeVerificationCodeCount(60);
    setIsRefresh(false);
    clearInterval(verificationCodeCountTimer);
    setQrcodeUrlTime(0);
    clearInterval(qrCodeTimer);
    changeRegistertatus(false);
    setIsWXBind(false);
    form.resetFields();
    loginForm.resetFields();
    forgotForm.resetFields();
  }

  // 进入鲸智云
  async function intoWhale(data: any) {
    const expand = ls.get(URL_EXPEND);
    const invite_token = ls.get(INVITE_TOKEN);
    if (expand || invite_token) {
      await mb_expand({ expand, invite_token });
      ls.set(URL_EXPEND, undefined);
      ls.set(INVITE_TOKEN, undefined);
    }
    // is_new === 1 则为第一次登录 第一次登录跳转信息补全页面
    if (data.user_info.is_new && data.user_info.is_new === 1) {
      window.location.replace('/addInfo');
      return;
    }
    window.location.replace('/home');
  }

  // 测试环境用于微信扫码的功能
  //const [testForm] = Form.useForm();
  //function testUserInfo() {
  //  const token = testForm.getFieldValue('token')
  //  //ls.set(ACCESS_TOKEN, token);
  //  if (!token) return;
  //  setBindToken(token)
  //  getUserInfo({
  //    app_id: 1004,
  //  },{
  //    headers: {
  //      "X-Token": token,
  //    },
  //  })
  //    .then((res: any) => {
  //      const { data, code, message } = res;
  //      if (code !== 0) {
  //        $message.error(message);
  //        return;
  //      }
  //      if (!data.phone) {
  //        setLoginMode('verificationCode')
  //        setIsWXBind(true)
  //      } else {
  //        setIsWXBind(false)
  //        // 扫码是否已同意过协议
  //        if (data.agreement) {
  //          ls.set(USER_INFO, data);
  //          ls.set(ACCESS_TOKEN, token);
  //          if (data?.company?.company_id) ls.set(COMPANY_ID, data.company.company_id);
  //          intoWhale();
  //        } else {
  //          confirm({
  //            ...confirmOptions,
  //            onOk: () => {
  //               // 是否同意用户协议
  //              userAgreement({
  //                agreement: 1
  //                },{
  //                headers: {
  //                  "X-Token": token,
  //                }
  //              }).then(() => {
  //                ls.set(USER_INFO, data);
  //                ls.set(ACCESS_TOKEN, token);
  //                if (data?.company?.company_id) ls.set(COMPANY_ID, data.company.company_id);
  //                intoWhale();
  //              });
  //            },
  //            onCancel: () => {
  //              userAgreement({agreement: 0},{
  //                headers: {
  //                  "X-Token": token,
  //                }
  //              }).then(() => {
  //                getWxCode('refresh');
  //              });
  //            },
  //          });
  //        }
  //      }
  //    })
  //    .catch((err) => {
  //      $message.error(err.message);
  //    });
  //}

  function md5Hash(input: any) {
    return CryptoJS.MD5(input).toString();
  }
  /**
   * 获取二维码
   * @param type init 初始化 refresh 刷新
   */
  function getWxCode(type: string) {
    const uid = md5Hash(Math.floor(Math.random() * 100000).toString());

    //changeModalShowStatus(true);
    //qrCodeTimer = setInterval(() => {
    //  getWxScan(uid);
    //  setQrcodeUrlTime(() => 300 - 1);
    //}, 1000);

    const invitation_code = ls.get(INVITE_TOKEN);
    const expand = ls.get(URL_EXPEND);
    if (type === 'init') {
      changeModalShowStatus(true);
    }
    wxCode({
      id: uid,
      invite_token: invitation_code,
      expand: expand,
    }).then((res: any) => {
      const { code, data, msg } = res;
      if (code !== 0) {
        setIsRefresh(true);
        return $message.error(msg);
      }
      const { url, ttl } = data;
      setQrcodeUrl(url);
      setQrcodeUrlTime(ttl);
      setIsRefresh(false);

      qrCodeTimer = setInterval(() => {
        getWxScan(uid);
        setQrcodeUrlTime((ttl) => ttl - 1);
      }, 1000);
    });
  }

  /**
   * 微信扫码获取登录结果
   * @param UniqueId 随机id
   */
  function getWxScan(UniqueId: string) {
    wxScan({
      id: UniqueId,
    }).then((res: any) => {
      const { code, data, msg } = res;
      if (code !== 0) {
        setQrcodeUrlTime(0);
        clearInterval(qrCodeTimer);
        if (code === 400) {
          setIsRefresh(true);
        }
        return $message.error(msg);
      }

      if (data && data.id) {
        clearInterval(qrCodeTimer);

        setBindToken(data.token);
        ls.set(BIND_TOKEN, data.token);
        // 扫码没有手机号 需要进行绑定操作
        if (!data.phone) {
          setLoginMode('verificationCode');
          setIsWXBind(true);
        } else {
          setIsWXBind(false);
          // 扫码是否已同意过协议
          if (data.agreement) {
            ls.set(WHALE_WISDOM_CLOUDS_TOKEN, data.token);
            changeModalShowStatus(false);
            getUserInfoToken();
          } else {
            const token = ls.get(BIND_TOKEN);
            const Authorization = `Bearer ${token}`;
            confirm({
              ...confirmOptions,
              onOk: () => {
                // 是否同意用户协议
                userAgreement(
                  {
                    agreement: 1,
                  },
                  {
                    headers: {
                      Authorization: Authorization,
                    },
                  }
                ).then((res) => {
                  if (res.code === 0) {
                    ls.set(WHALE_WISDOM_CLOUDS_TOKEN, data.token);
                    changeModalShowStatus(false);
                    getUserInfoToken();
                  } else {
                    return $message.error('网络出错，请重试');
                  }
                });
              },
              onCancel: () => {
                userAgreement(
                  { agreement: 0 },
                  {
                    headers: {
                      Authorization: Authorization,
                    },
                  }
                ).then(() => {
                  if (res.code === 0) {
                    getWxCode('refresh');
                  } else {
                    return $message.error('网络出错，请重试');
                  }
                });
              },
            });
          }
        }
      }
    });
  }

  function handleRefreshQrCode() {
    getWxCode('refresh');
  }
  /**
   *  立即登录
   */
  //const loginNow = () => {
  //  changeCountdown(false);
  //  changeVerificationCodeCount(60);
  //  setQrcodeUrlTime(0);
  //  setTypeStr('account');
  //  form.resetFields(); // 注册form
  //  loginForm.resetFields(); // 登录form
  //  forgotForm.resetFields(); // 忘记密码form
  //};

  /**
   *  失焦校验手机号
   */
  //const validPhone = async (_rule: any, value: string) => {
  //  if (!value) {
  //    return Promise.reject('请输入手机号');
  //  }
  //  if (!isMobile(forgotForm.getFieldValue('username'))) {
  //    return Promise.reject('手机号格式不正确');
  //  }
  //  return Promise.resolve();
  //};
  /**
   *  校验验证码
   */
  //const validCode = async (_rule: any, value: string) => {
  //  if (!value) {
  //    return Promise.reject('请输入验证码');
  //  }
  //  return Promise.resolve();
  //};

  /**
   *  获取验证码
   */
  const getVerificationCode = (str: string) => {
    const { validateFields } = str === 'register' ? form : str === 'login' ? loginForm : forgotForm;
    validateFields([str === 'register' ? 'phone' : 'username'])
      .then((val) => {
        const phone = str === 'register' ? val.phone : val.username;
        if (!isMobile(phone)) {
          $message.error('手机号格式不正确');
          return;
        }
        if (isWXBind) {
          handleVerificationCodeService(loginForm.getFieldValue('username'), 'bind');
        } else {
          handleVerificationCodeService(phone, str);
        }
      })
      .catch((err) => {
        console.log('err===========>', err);
      });
  };

  function handleVerificationCodeService(phone: string, str: string) {
    const Authorization = `Bearer ${bindToken}`;
    const xToken =
      str === 'bind'
        ? {
            headers: {
              Authorization: Authorization,
            },
          }
        : '';
    getVerificationCodeService(
      {
        phone: phone,
        type: str === 'forgot' ? 'update_password' : str,
        source: originSkyink ? 'mb' : 'jzy',
      },
      xToken
    )
      .then((res: any) => {
        const { code, msg } = res;
        if (code !== 0) {
          if (res.code === 2164260881) {
            return false;
          }
          $message.error(msg);
          return;
        }
        $message.success('短信发送成功');
        changeCountdown(true);
        verificationCodeCountTimer = setInterval(() => {
          changeVerificationCodeCount((verificationCodeCount) => verificationCodeCount - 1);
        }, 1000);
      })
      .catch((err: any) => {
        $message.error(err.msg);
      });
  }

  /**
   * 切换登录方式 扫码 or 验证码
   */
  function handleIdentitySwitch() {
    const currentMode = loginMode === 'verificationCode' ? 'qrCode' : 'verificationCode';
    setLoginMode(currentMode);
    if (qrCodeTimer) {
      clearInterval(qrCodeTimer);
    }
    if (currentMode === 'verificationCode') {
      clearInterval(qrCodeTimer);
    } else {
      loginForm.resetFields();
      getWxCode('refresh');
    }
    //ls.set(USER_TYPE, type);
  }

  /**
   * 登录接口调用
   * @param username 手机号
   * @param password 验证码
   */
  function handleLogin(username: string, password: string) {
    const invitation_code = ls.get(INVITE_TOKEN);
    const expand = ls.get(URL_EXPEND);
    setButtonLoading(true);
    login({
      username,
      password: window.btoa(password),
      type: 4,
      invite_token: invitation_code,
      expand: expand,
      channel: 7,
    })
      .then((res: any) => {
        setButtonLoading(false);
        const { code, data, msg } = res;
        if (code !== 0) {
          if (res.code === 2164260881) {
            return false;
          }
          $message.error(msg);
          return;
        }
        if (!data.token) return;
        ls.set(WHALE_WISDOM_CLOUDS_TOKEN, data.token);
        getUserInfoToken();
        handleCancel();
      })
      .catch((err: any) => {
        setButtonLoading(false);
        $message.error(err.msg);
      });
  }

  const getUserInfoToken = (typeStr?: string) => {
    const bindToken = ls.get(BIND_TOKEN);
    const Authorization = `Bearer ${bindToken}`;
    const xToken =
      typeStr === 'bind'
        ? {
            headers: {
              Authorization: Authorization,
            },
          }
        : '';
    getUserInfo({}, xToken)
      .then((res: any) => {
        const { data, code, message } = res;
        handleCancel(typeStr);
        if (code !== 0) {
          if (typeStr === 'bind') {
            return $message.info('绑定成功，请重新登录', 5);
          }
          return $message.error(message, 5);
        }
        if (typeStr === 'bind') {
          ls.set(WHALE_WISDOM_CLOUDS_TOKEN, bindToken);
        }
        if (data?.account_info)
          ls.set(COMPANY_ID, {
            uid: data?.account_info.user_id,
            cid: data?.account_info.company_id,
          });
        store.dispatch(setUserInfo(data.user_info));
        store.dispatch(setAccountInfo(data.account_info));
        store.dispatch(setCompanyInfo(data.company_info));
        store.dispatch(setMessageInfo(data.message_info));
        intoWhale(data);
      })
      .catch((err: any) => {
        setButtonLoading(false);
        $message.error(err.message);
      });
  };

  // 登录
  const submitLoginform = () => {
    const { validateFields } = loginForm;
    validateFields()
      .then((val) => {
        const { username, password } = val;
        // 没有勾选状态或者不是微信绑定状态的时候
        if (!isCheck && !isWXBind) {
          confirm({
            ...confirmOptions,
            onOk: () => {
              setIsCheck(true);
              handleLogin(username, password);
            },
            onCancel() {
              setIsCheck(false);
            },
          });
        } else {
          if (isWXBind) {
            const bindToken = ls.get(BIND_TOKEN);
            const Authorization = `Bearer ${bindToken}`;
            confirm({
              ...confirmOptions,
              onOk: () => {
                userAgreement(
                  { agreement: 1 },
                  {
                    headers: {
                      Authorization: Authorization,
                    },
                  }
                ).then((res) => {
                  if (res.code === 0) {
                    handleBindPhone(username, password);
                  } else {
                    return $message.error('网络出错，请重试');
                  }
                });
              },
              onCancel() {
                userAgreement(
                  { agreement: 0 },
                  {
                    headers: {
                      Authorization: Authorization,
                    },
                  }
                ).then((res) => {
                  if (res.code === 0) {
                    $message.info('未登录成功，请重新扫码绑定手机号登录');
                    setBindToken(undefined);
                    setIsWXBind(false);
                    changeCountdown(false);
                    changeVerificationCodeCount(60);
                    clearInterval(verificationCodeCountTimer);
                    setLoginMode('qrCode');
                    loginForm.resetFields();
                    getWxCode('refresh');
                  } else {
                    return $message.error('网络出错，请重试');
                  }
                });
              },
            });
          } else {
            handleLogin(username, password);
          }
        }
      })
      .catch((err) => {
        console.log('err===========>', err);
      });
  };

  // 微信扫码情况下 绑定手机号后进行登录操作
  function handleBindPhone(username: string, password: string) {
    const expand = ls.get(URL_EXPEND);
    const invite_token = ls.get(INVITE_TOKEN);
    const bindToken = ls.get(BIND_TOKEN);
    const Authorization = `Bearer ${bindToken}`;
    bindPhone(
      {
        invite_token,
        expand,
        phone: username,
        code: password,
        type: '1',
      },
      {
        headers: {
          Authorization: Authorization,
        },
      }
    ).then((res: any) => {
      const { code, msg } = res;
      if (code !== 0) {
        return $message.error(msg);
      }
      setIsWXBind(false);
      getUserInfoToken('bind');
    });
  }

  useImperativeHandle(ref, () => ({
    init,
  }));

  useEffect(() => {
    if (verificationCodeCount <= 0) {
      clearInterval(verificationCodeCountTimer);
      changeCountdown(false);
      changeVerificationCodeCount(60);
    }
  }, [verificationCodeCount]);

  useEffect(() => {
    if (qrcodeUrlTime < 0) {
      clearInterval(qrCodeTimer);
      setQrcodeUrlTime(0);
      setIsRefresh(true);
    }
  }, [qrcodeUrlTime]);

  return (
    <>
      <Modal
        centered
        wrapClassName="loginModal"
        open={isShow}
        footer={false}
        width={800}
        style={{ pointerEvents: 'auto' }}
        modalRender={() => {
          return (
            <div className="loginContainer">
              {!isRegisterSuccess && (
                <div className="loginContent">
                  <div className="loginLeft" />
                  <div className="loginRight">
                    <div>
                      <div
                        onClick={() => handleCancel()}
                        style={{
                          position: 'absolute',
                          right: -35,
                          top: -30,
                          cursor: 'pointer',
                          width: 30,
                          height: 30,
                          color: 'white',
                          fontSize: '22px',
                        }}
                      >
                        <CloseOutlined />
                      </div>
                    </div>
                    {!isWXBind && !originSkyink && (
                      <div className="btnIdentitySwitch" onClick={handleIdentitySwitch}>
                        <img src={loginMode !== 'qrCode' ? codePng : iphonePng} alt="" />
                        <div className="switchTip">
                          {loginMode !== 'qrCode' ? '微信扫码登录' : '手机验证码登录'}
                        </div>
                      </div>
                    )}

                    <div className="loginTabs">
                      <h3>
                        {loginMode === 'qrCode'
                          ? '微信扫码登录'
                          : isWXBind
                          ? '绑定手机号'
                          : '手机验证码登录'}
                      </h3>
                      {loginMode === 'verificationCode' ? (
                        <Form
                          form={loginForm}
                          wrapperCol={{ span: 24 }}
                          initialValues={{ remember: true }}
                          autoComplete="off"
                        >
                          <Form.Item
                            name="username"
                            rules={[{ required: true, message: '请输入手机号' }]}
                          >
                            <Input
                              size="large"
                              style={{ borderRadius: 4 }}
                              placeholder={'请输入手机号'}
                            />
                          </Form.Item>

                          <>
                            {!isCountdown && (
                              <Form.Item className="sendCodeFormItem">
                                <Space>
                                  <Form.Item
                                    name="password"
                                    rules={[
                                      {
                                        required: true,
                                        message: '请输入验证码',
                                      },
                                    ]}
                                    noStyle
                                  >
                                    <Input
                                      size="large"
                                      style={{ borderRadius: 4, width: '235px' }}
                                      placeholder={'验证码'}
                                    />
                                  </Form.Item>
                                  <Button size="large" onClick={() => getVerificationCode('login')}>
                                    {'获取验证码'}
                                  </Button>
                                </Space>
                              </Form.Item>
                            )}
                            {isCountdown && (
                              <Form.Item
                                name="password"
                                rules={[
                                  {
                                    required: true,
                                    message: '请输入验证码',
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  style={{ borderRadius: 4 }}
                                  placeholder={'验证码'}
                                  suffix={<span>{verificationCodeCount}s重新发送</span>}
                                />
                              </Form.Item>
                            )}
                          </>
                          <Form.Item>
                            <div className="formBotton">
                              <Button
                                loading={buttonLoading}
                                onClick={submitLoginform}
                                type="primary"
                                size="large"
                              >
                                {isWXBind ? '立即绑定' : '登录'}
                              </Button>
                            </div>
                          </Form.Item>
                        </Form>
                      ) : (
                        <div className="wxQrCodeContent">
                          <div className="wxQrCode">
                            {isRefresh && (
                              <div className="expired" onClick={handleRefreshQrCode}>
                                <p className="refresh">
                                  <SyncOutlined />
                                  <span>二维码过期，点击刷新</span>
                                </p>
                              </div>
                            )}
                            <QRCode
                              id="wx_qr_code_url"
                              value={qrcodeUrl} //value参数为生成二维码的链接 我这里是由后端返回
                              size={186} //二维码的宽高尺寸
                              fgColor="#000000" //二维码的颜色
                            />
                          </div>
                          {/*<Form
                            form={testForm}
                            name="testForm"
                          >
                            <Form.Item
                              label="token"
                              name="token"
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                            >
                              <Button onClick={testUserInfo}>获取</Button>
                            </Form.Item>
                            </Form>*/}
                        </div>
                      )}
                    </div>

                    {loginMode === 'verificationCode' && !isWXBind && (
                      <div className="agreeServe">
                        {isCheck ? (
                          <CheckSquareOutlined className="span" onClick={() => setIsCheck(false)} />
                        ) : (
                          <div
                            className="square"
                            onClick={() => {
                              setIsCheck(true);
                              setIsWXBind(false);
                            }}
                          ></div>
                        )}
                        您登录即同意
                        <span onClick={() => jumpServe('usage')}>《用户协议》</span>和
                        <span onClick={() => jumpServe('privacyPolicy')}>《隐私政策》</span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        }}
      ></Modal>
    </>
  );
});

LoginRegisterModal.displayName = 'LoginRegisterModal';

export default LoginRegisterModal;
