import { lazy, Suspense } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import IconFont from '@/components/IconFont';
import { isOriginSkyink } from '@/utils/index';
//layout
//import BasicLayout from '@/layouts/BasicLayout';
import MainLayout from '@/layouts/MainLayout';
import FirstLayout from '@/layouts/FirstLayout';
import PluginLayout from '@/layouts/PluginLayout';
import Loading from '@/components/loading';

//import Login from '@/pages/login/login';
import WhaleClouds from '@/pages/whaleClouds';
import NotFoundPage from '@/pages/404';
//const Chat = lazy(() => import('@/pages/chat'));
const Home = lazy(() => import('@/pages/home'));
const HomeDetails = lazy(() => import('@/pages/home/details'));
const XHS_Details = lazy(() => import('@/pages/home/details/xiaohongshu'));
const FirstPage = lazy(() => import('@/pages/firstPage'));
//const History = lazy(() => import('@/pages/history'));
const UserInfo = lazy(() => import('@/pages/user/info'));
const SubAccount = lazy(() => import('@/pages/user/subAccount'));
const UserRefueling = lazy(() => import('@/pages/user/refueling'));
//const UserInvite = lazy(() => import('@/pages/user/invite'));
const NewsList = lazy(() => import('@/pages/newsList'));
const PluginSquare = lazy(() => import('@/pages/pluginSquare'));
//审稿工具
const ExamineList = lazy(() => import('@/pages/aiReviewTool'));
const CreatExamineDocs = lazy(() => import('@/pages/aiReviewTool/docs/creat'));
const AiDocsExamine = lazy(() => import('@/pages/aiReviewTool/docs/details'));
const CreatExamineImg = lazy(() => import('@/pages/aiReviewTool/image/creat'));
const AiImgExamine = lazy(() => import('@/pages/aiReviewTool/image/details'));
const CreatExamineVideo = lazy(() => import('@/pages/aiReviewTool/video/creat'));
const AiVideoExamine = lazy(() => import('@/pages/aiReviewTool/video/details'));

const PictureAgain = lazy(() => import('@/pages/draw/pictureAgain'));
const PictureHistory = lazy(() => import('@/pages/draw/components/History'));
const TextDrawing = lazy(() => import('@/pages/draw/textToDrawing/creat'));
const ChangeBackground = lazy(() => import('@/pages/draw/changeBackground/creat'));
const PictureSquare = lazy(() => import('@/pages/draw/textToDrawing'));
const CountenanceCopy = lazy(() => import('@/pages/aiFacesWap/countenanceCopy')); // 表情复刻
// 文档提炼
const ExtractionList = lazy(() => import('@/pages/aiDocsExtraction'));
const AiDocsExtraction = lazy(() => import('@/pages/aiDocsExtraction/details'));
const CreatExtractionDocs = lazy(() => import('@/pages/aiDocsExtraction/creat'));
const XhsCommentMonitor = lazy(() => import('@/pages/pluginSquare/CommentMonitor'));
// ai换脸
const FacesWapHistory = lazy(() => import('@/pages/aiFacesWap/history'));
const CountenanceHistory = lazy(
  () => import('@/pages/aiFacesWap/countenanceCopy/components/countenanceHistory')
);
const CreatFacesWap = lazy(() => import('@/pages/aiFacesWap/creat'));
const FacesWap = lazy(() => import('@/pages/aiFacesWap'));
// 语音生成
const Voices = lazy(() => import('@/pages/aiVoiceChange'));
const VoicesHistory = lazy(() => import('@/pages/aiVoiceChange/history'));
// 智能换背景
const IntelligentChangeBg = lazy(() => import('@/pages/aiIntelligentChangeBg'));
const IntelligentHistory = lazy(() => import('@/pages/aiIntelligentChangeBg/history'));

// 上层加载
const lazyComponent = (element: JSX.Element) => {
  return <Suspense fallback={<Loading />}>{element}</Suspense>;
};

const baseRoutes: any = [
  {
    //path: '/',
    path: '/welcome',
    auth: false, // 是否需要登录
    title: '首页',
    element: <>{lazyComponent(<WhaleClouds />)}</>,
    //children: [
    //	{
    //		path: '/welcome',
    //		auth: false, // 是否需要登录
    //		title: '首页',
    //		element: <>{lazyComponent(<WhaleClouds />)}</>,
    //	},
    //],
  },
];

const layoutRoutes: any = [
  { path: '/', element: <Navigate to="/welcome" /> },
  {
    path: '/',
    auth: true, // 是否需要登录
    title: '登录',
    element: <FirstLayout />,
    children: [
      {
        path: '/addInfo',
        auth: true, // 是否需要登录
        name: '添加用户资料',
        title: '添加用户',
        element: <>{lazyComponent(<FirstPage />)}</>,
      },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      //{
      //	path: '/chat',
      //	name: '小云问答',
      //	title: '小云问答',
      //	auth: true, // 是否需要登录
      //	icon: (
      //		<IconFont type="icon-duihua" style={{ fontSize: '22px', marginRight: '5px' }} />
      //	), // 菜单栏图标
      //	isMenu: true, // 是否菜单栏显示
      //	element: <>{lazyComponent(<Chat />)}</>,
      //},
      {
        path: '/home',
        name: 'AI文案',
        title: 'AI文案',
        auth: true, // 是否需要登录
        icon: <IconFont type="icon-guangchang" style={{ fontSize: '22px', marginRight: '5px' }} />, // 菜单栏图标
        isMenu: true, // 是否菜单栏显示
        element: <>{lazyComponent(<Home />)}</>,
      },
      {
        path: '/draw',
        name: 'AI绘图',
        title: 'AI绘图',
        auth: true, // 是否需要登录
        icon: <IconFont type="icon-huihua" style={{ fontSize: '22px', marginRight: '5px' }} />,
        isMenu: true,
        children: [
          { path: '/draw', element: <Navigate to="/draw/picture_again" /> },
          {
            path: '/draw/picture_again',
            name: 'AI生图',
            title: 'AI生图',
            auth: true,
            isMenu: true,
            element: <>{lazyComponent(<PictureAgain />)}</>,
          },
          {
            path: '/draw/picture_again/:taskId?',
            name: 'AI生图-做同款',
            title: 'AI生图-做同款',
            auth: true,
            isMenu: false,
            element: <>{lazyComponent(<PictureAgain />)}</>,
          },
          {
            path: '/draw/picture_again_history',
            name: 'AI生图历史',
            title: 'AI生图历史',
            auth: true,
            isMenu: false,
            element: <>{lazyComponent(<PictureHistory />)}</>,
          },
          {
            path: '/draw/picture_square',
            name: '文生图',
            title: '文生图',
            auth: true,
            isMenu: true,
            element: <>{lazyComponent(<PictureSquare />)}</>,
          },
          {
            path: '/draw/creat_text2draw/:uid?/:type?',
            name: '文生图创作',
            title: '文生图创作',
            auth: true,
            isMenu: false,
            element: <>{lazyComponent(<TextDrawing />)}</>,
          },
          {
            path: '/draw/creat_background',
            name: 'AI换背景',
            title: 'AI换背景',
            auth: true,
            isMenu: true,
            element: <>{lazyComponent(<ChangeBackground />)}</>,
          },
          {
            path: '/draw/creat_background/:taskId?',
            name: 'AI换背景-做同款',
            title: 'AI换背景-做同款',
            auth: true,
            isMenu: false,
            element: <>{lazyComponent(<ChangeBackground />)}</>,
          },
          {
            path: '/draw/change_background',
            name: 'AI换背景历史',
            title: 'AI换背景历史',
            auth: true,
            isMenu: false,
            element: <>{lazyComponent(<PictureHistory />)}</>,
          },
        ],
      },
      {
        path: '/faceswap',
        name: 'AI视频',
        title: 'AI视频',
        auth: true, // 是否需要登录
        icon: <IconFont type="icon-AIshipin" style={{ fontSize: '22px', marginRight: '5px' }} />,
        isMenu: true,
        children: [
          {
            path: '/faceswap',
            name: '视频换脸',
            title: '视频换脸',
            auth: true,
            isMenu: true,
            element: <>{lazyComponent(<FacesWap />)}</>,
          },
          {
            path: '/faceswap/history/:navActive?',
            name: '视频换脸历史',
            title: '视频换脸历史',
            auth: true,
            isMenu: false,
            element: <>{lazyComponent(<FacesWapHistory />)}</>,
          },
          {
            path: '/faceswap/edit/:id?',
            name: '视频换脸',
            title: '视频换脸',
            auth: true,
            isMenu: false,
            element: <>{lazyComponent(<CreatFacesWap />)}</>,
          },
          {
            path: '/faceswap/voice',
            name: '语音替换',
            title: '语音替换',
            auth: true,
            isMenu: true,
            element: <>{lazyComponent(<Voices />)}</>,
          },
          {
            path: '/faceswap/voice/history',
            name: '语音替换历史',
            title: '语音替换历史',
            auth: true,
            isMenu: false,
            element: <>{lazyComponent(<VoicesHistory />)}</>,
          },
          {
            path: '/faceswap/voice/edit/:id?',
            name: '语音替换',
            title: '语音替换',
            auth: true,
            isMenu: false,
            element: <>{lazyComponent(<Voices />)}</>,
          },
          {
            path: '/faceswap/countenance_copy',
            name: '表情复刻',
            title: '表情复刻',
            auth: true,
            isMenu: true,
            element: <>{lazyComponent(<CountenanceCopy />)}</>,
          },
          {
            path: '/faceswap/countenance_copy/copy/:id?',
            name: '表情复刻',
            title: '表情复刻',
            auth: true,
            isMenu: false,
            element: <>{lazyComponent(<CountenanceCopy />)}</>,
          },
          {
            path: '/faceswap/countenance_copy_history',
            name: '表情复刻历史',
            title: '表情复刻历史',
            auth: true,
            isMenu: false,
            element: <>{lazyComponent(<CountenanceHistory />)}</>,
          },
          {
            path: '/faceswap/changeBg',
            name: '智能换背景',
            title: '智能换背景',
            auth: true,
            isMenu: true,
            element: <>{lazyComponent(<IntelligentChangeBg />)}</>,
          },
          {
            path: '/faceswap/changeBg/edit/:id?',
            name: '智能换背景',
            title: '智能换背景',
            auth: true,
            isMenu: false,
            element: <>{lazyComponent(<IntelligentChangeBg />)}</>,
          },
          {
            path: '/faceswap/changeBg/history',
            name: '智能换背景历史',
            title: '智能换背景历史',
            auth: true,
            isMenu: false,
            element: <>{lazyComponent(<IntelligentHistory />)}</>,
          },
        ],
      },
      {
        path: '/home/details',
        name: '生成文案',
        title: '生成文案',
        auth: true, // 是否需要登录
        isMenu: false, // 是否菜单栏显示
        element: <>{lazyComponent(<HomeDetails />)}</>,
      },
      {
        path: '/home/xhs_details',
        name: '生成文案',
        title: '生成文案',
        auth: true, // 是否需要登录
        isMenu: false, // 是否菜单栏显示
        element: <>{lazyComponent(<XHS_Details />)}</>,
      },
      //{
      //  path: '/history',
      //  name: '创作历史',
      //  title: '创作历史',
      //  auth: true, // 是否需要登录
      //  icon: <IconFont type="icon-lishi" style={{ fontSize: '22px', marginRight: '5px' }} />,
      //  isMenu: true, // 是否菜单栏显示
      //  element: <>{lazyComponent(<History />)}</>,
      //},
      {
        path: '/square',
        name: '插件广场',
        title: '插件广场',
        auth: true, // 是否需要登录
        icon: <IconFont type="icon-chajian" style={{ fontSize: '22px', marginRight: '5px' }} />, // 菜单栏图标
        isMenu: true, // 是否菜单栏显示
        element: <>{lazyComponent(<PluginSquare />)}</>,
      },
      // {
      //   path: '/user/invite',
      //   name: '推荐返佣',
      //   title: '推荐返佣',
      //   auth: true,
      //   icon: <IconFont type="icon-tuijian" style={{ fontSize: '22px', marginRight: '5px' }} />, // 菜单栏图标
      //   isMenu: true, // 是否菜单栏显示
      //   element: <>{lazyComponent(<UserInvite />)}</>,
      // },
      {
        path: '/user',
        name: '个人中心',
        title: '个人中心',
        auth: true, // 是否需要登录
        icon: (
          <IconFont type="icon-gerenzhongxin" style={{ fontSize: '22px', marginRight: '5px' }} />
        ),
        isMenu: true, // 是否菜单栏显示
        children: [
          { path: '/user', element: <Navigate to="/user/info" /> },
          {
            path: '/user/info',
            name: '个人资料',
            title: '个人资料',
            auth: true, // 是否需要登录
            isMenu: true, // 是否菜单栏显示
            element: <>{lazyComponent(<UserInfo />)}</>,
          },
          {
            path: '/user/sub_account',
            name: '子账号管理',
            title: '子账号管理',
            hidden: true,
            auth: true, // 是否需要登录
            isMenu: true, // 是否菜单栏显示
            element: <>{lazyComponent(<SubAccount />)}</>,
          },
          {
            path: '/user/refueling',
            name: '加油包',
            title: '加油包',
            auth: true, // 是否需要登录
            isMenu: true, // 是否菜单栏显示
            element: <>{lazyComponent(<UserRefueling />)}</>,
          },
        ],
      },
      {
        path: '/user/new_list',
        name: '更新日志',
        title: '更新日志',
        auth: true,
        isMenu: false, // 是否菜单栏显示
        element: <>{lazyComponent(<NewsList />)}</>,
      },
      { path: '*', element: <Navigate to="/404" /> },
      {
        path: '/404',
        element: (
          <>
            <NotFoundPage />
          </>
        ),
      },
    ],
  },
  {
    path: '/',
    element: <PluginLayout />,
    children: [
      {
        path: '/examine_list',
        name: 'AI审稿工具',
        title: 'AI审稿工具',
        auth: true,
        isMenu: false,
        element: <>{lazyComponent(<ExamineList />)}</>,
      },
      {
        path: '/creat_examine_docs',
        name: '开始审稿',
        title: '开始审稿',
        auth: true,
        isMenu: false,
        element: <>{lazyComponent(<CreatExamineDocs />)}</>,
      },
      {
        path: '/document_examine/:union_id',
        name: 'AI审稿',
        title: 'AI审稿',
        auth: true, // 是否需要登录
        isMenu: false, // 是否菜单栏显示
        element: <>{lazyComponent(<AiDocsExamine />)}</>,
      },
      {
        path: '/creat_examine_img',
        name: '图片审核',
        title: '图片审核',
        auth: true,
        isMenu: false,
        element: <>{lazyComponent(<CreatExamineImg />)}</>,
      },
      {
        path: '/image_examine/:union_id',
        name: '图片审核详情',
        title: '图片审核详情',
        auth: true, // 是否需要登录
        isMenu: false, // 是否菜单栏显示
        element: <>{lazyComponent(<AiImgExamine />)}</>,
      },
      {
        path: '/creat_examine_video',
        name: '视频审核',
        title: '视频审核',
        auth: true,
        isMenu: false,
        element: <>{lazyComponent(<CreatExamineVideo />)}</>,
      },
      {
        path: '/video_examine/:review_id',
        name: '视频审核详情',
        title: '视频审核详情',
        auth: true, // 是否需要登录
        isMenu: false, // 是否菜单栏显示
        element: <>{lazyComponent(<AiVideoExamine />)}</>,
      },
      {
        path: '/extraction_list',
        name: '文档提炼',
        title: '文档提炼',
        auth: true,
        isMenu: false,
        element: <>{lazyComponent(<ExtractionList />)}</>,
      },
      {
        path: '/creat_extraction_docs',
        name: '文档信息提炼',
        title: '文档信息提炼',
        auth: true,
        isMenu: false,
        element: <>{lazyComponent(<CreatExtractionDocs />)}</>,
      },
      {
        path: '/document_extraction/:union_id',
        name: '文档信息提炼工具',
        title: '文档信息提炼工具',
        auth: true,
        isMenu: false,
        element: <>{lazyComponent(<AiDocsExtraction />)}</>,
      },
      {
        path: '/plugin/comment_monitor',
        name: '小红书评论监控',
        title: '小红书评论监控',
        auth: true,
        isMenu: false,
        element: <>{lazyComponent(<XhsCommentMonitor />)}</>,
      },
    ],
  },
];

export const routes: any = [
  ...baseRoutes,
  ...layoutRoutes,
  { path: '*', element: <Navigate to="/404" /> },
  {
    path: '/404',
    element: (
      <>
        <NotFoundPage />
      </>
    ),
  },
];

function Router() {
  return useRoutes(routes);
}

//根据路径获取路由
const checkAuth = (routers: any, path: String) => {
  for (const data of routers) {
    if (data.path == path) return data;
    if (data.children) {
      const res: any = checkAuth(data.children, path);
      if (res) return res;
    }
  }
  return null;
};

const originStr = isOriginSkyink() ? '云端妙笔' : '鲸智云';
const checkRouterAuth = (path: string) => {
  let auth = null;
  auth = checkAuth(routes, path);
  if (path.includes('creat_text2draw')) {
    document.title = `文生图 - ${originStr}`;
  } else if (path.includes('picture_again')) {
    document.title = `AI生图 - ${originStr}`;
  } else if (path.includes('creat_background')) {
    document.title = `AI换背景 - ${originStr}`;
  } else if (path.includes('faceswap')) {
    document.title = `AI视频 - ${originStr}`;
  } else if (
    path.includes('image_examine') ||
    path.includes('video_examine') ||
    path.includes('document_examine')
  ) {
    document.title = `AI审稿详情 - ${originStr}`;
  } else if (path.includes('document_extraction')) {
    document.title = `文档提炼 - ${originStr}`;
  } else {
    document.title = `${auth?.title || '应用'} - ${originStr}`;
  }
  return auth;
};

export default Router;
export { checkRouterAuth };
