import { useState, forwardRef, Ref, useImperativeHandle } from 'react';
import { Modal } from 'antd';
import './index.less';
import { isOriginSkyink } from '@/utils/index';
import service from '@/assets/recharge/service.png';
import serviceYDMB from '@/assets/recharge/service-ydmb.png';
import consult from '@/assets/recharge/consult.png';

const originQR = isOriginSkyink() ? serviceYDMB : service;

const ConsultModal = forwardRef((_props, ref: Ref<any>) => {
  const [consultModal, setConsultModal] = useState(false);

  const init = () => {
    setConsultModal(true);
  };

  const handleCancel = async () => {
    setConsultModal(false);
  };

  useImperativeHandle(ref, () => ({
    init,
  }));

  return (
    <>
      <Modal
        wrapClassName="consult"
        width={526}
        centered
        open={consultModal}
        maskClosable={false}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          <h3>联系商务</h3>
          <div className="consultContent">
            <img src={consult} alt="" />
            <p>
              <span>微信扫码添加商务经理</span>
              <span>立即咨询</span>
            </p>
            <img src={originQR} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
});

export default ConsultModal;
