import { $get, $post } from '@/utils/axios';
import { cloudsApi, manageApi, lianshanApi } from '@/utils/https';
// 用户会员套餐接口
export async function memberPackageList(): Promise<any> {
  return $post(cloudsApi + '/user/member-package-list');
}

// 用户会员套餐支付
export async function orderPayment(parameter: any): Promise<any> {
  return $post(cloudsApi + '/order/payment', parameter);
}

// 用户会员套餐支付确认
export async function paymentCheck(parameter: any): Promise<any> {
  return $post(cloudsApi + '/order/payment-check', parameter);
}

// 获取验证码
export async function phoneCode(parameter: any): Promise<any> {
  return $post(manageApi + '/sms/send', parameter);
}

// 用户绑定手机
export async function bindPhone(parameter: any): Promise<any> {
  return $post(cloudsApi + '/user/accout-bind', parameter);
}

// 用户更新手机号码
export async function updatePhone(parameter: any): Promise<any> {
  return $post(cloudsApi + '/user/phone-update', parameter);
}

// 用户推荐分享
export async function inviterInfo(parameter: any): Promise<any> {
  return $post(cloudsApi + '/user/inviter-info', parameter);
}

// 用户绑定邀请码
export async function inviterBind(parameter: any): Promise<any> {
  return $post(cloudsApi + '/user/inviter-bind', parameter);
}

/**
 * 获取七牛上传token(公司)
 */
export async function getQiniuToken(parameter: any): Promise<any> {
  return $post(cloudsApi + '/common/get-token', parameter);
}

/**
 * 获取加油包列表
 */
export async function getPackageList(parameter: any): Promise<any> {
  return $post(cloudsApi + '/package/list', parameter);
}

/**
 * 获取企业用户列表
 */
export async function getCompanyUserList(parameter: any): Promise<any> {
  return $post(cloudsApi + '/company/user-list', parameter);
}

/**
 * 微信公众号获取二维码url(公众号登陆)
 */
export async function wechatCode(parameter: any): Promise<any> {
  return $post(manageApi + '/wx/code', parameter);
}

/**
 * 微信公众号轮询获取登录结果
 */
export async function wechatScan(parameter: any): Promise<any> {
  return $get(manageApi + '/wx/scan', parameter);
}

/**
 * 用户修改密码
 */
export async function updatePassword(parameter: any): Promise<any> {
  return $get(cloudsApi + '/user/password-reset', parameter);
}

/**
 * 用户修改信息
 */
export async function updateUserInfo(parameter: any): Promise<any> {
  return $post(cloudsApi + '/user/info-set', parameter);
}

/**
 * 从云端妙笔登录连山
 */
export async function skyinkLogin(parameter: any): Promise<any> {
  const api =
    process.env.NODE_ENV === 'development' ? lianshanApi : process.env.REACT_APP_LIAN_SHAN_API_URL;
  return $post(api + '/saas/account/skyink_company_login', parameter);
}

/**
 * 用户消息列表
 */
export async function getMessageList(parameter: any): Promise<any> {
  return $post(cloudsApi + '/user/message-list', parameter);
}

/**
 * 用户消息详情
 */
export async function getMessageInfo(parameter: any): Promise<any> {
  return $post(cloudsApi + '/user/message-info', parameter);
}

/**
 * 用户消息设置已读
 */
export async function getMessageRead(parameter: any): Promise<any> {
  return $post(cloudsApi + '/user/message-read', parameter);
}

/**
 * 用户消息未读个数
 */
export async function getMessageCount(): Promise<any> {
  return $post(cloudsApi + '/user/message-count');
}

// 文件上传七牛云（后端要求更换成他们上传七牛云，返回url给前端）
export async function uploadFile(parameter: any, _object?: any): Promise<any> {
  return $post(cloudsApi + '/common/upload-file', parameter, _object);
}

// ai换背景 用户额外信息修改
export async function extraInfoSet(parameter: any): Promise<any> {
  return $post(cloudsApi + '/user/extra-info-set', parameter);
}

export async function plugCommentMonitorAdd(parameter: any): Promise<any> {
  return $post(cloudsApi + '/plug/comment-monitor-add', parameter);
}

export async function plugCommentMonitorEdit(parameter: any): Promise<any> {
  return $post(cloudsApi + '/plug/comment-monitor-edit', parameter);
}
export async function plugCommentMonitorInfo(parameter: any): Promise<any> {
  return $post(cloudsApi + '/plug/comment-monitor-info', parameter);
}
export async function plugCommentMonitorList(parameter: any): Promise<any> {
  return $post(cloudsApi + '/plug/comment-monitor-list', parameter);
}
